import {Header as MarketingHeader} from '@/domains/marketing/components/Header';
import {MagnifyingGlassIcon}       from '@heroicons/react/20/solid';
import classNames                  from 'classnames';
import Link                        from 'next/link';

export function Header({
	                       className,
	                       navigation,
	                       currentNavigation,
                       }) {
	return (<MarketingHeader className={className}>
		<div className="hidden lg:flex items-end justify-between gap-x-12">
			<div className="flex-1 hidden lg:flex items-center gap-x-12 pb-3">
				<div className="font-semibold text-sm text-gray-900 leading-6">
					RecruitKit Docs
				</div>

				<div className="hidden lg:flex lg:gap-x-12">
					{Object.entries(navigation)
					       .map(([key, item]) => (<Link key={item.title}
					                                    href={item.href}
					                                    className={classNames([
						                                    'text-sm font-semibold leading-6 text-gray-900 border-b-2 -mb-3 pb-3',
						                                    key === currentNavigation ? 'font-semibold text-rose-600 before:bg-rose-600 border-rose-600' : 'text-slate-500 before:hidden before:bg-slate-300 hover:text-slate-600 hover:before:block dark:text-slate-400 dark:before:bg-slate-700 dark:hover:text-slate-300 border-transparent',
					                                    ])}
					       >
						       {item.title}
					       </Link>))}
				</div>
			</div>

			{false && <div className="w-full max-w-lg lg:max-w-xs pb-2">
				{/* TODO: Turn this into a button that opens the search bar instead */}
				<label htmlFor="search"
				       className="sr-only"
				>
					Search
				</label>

				<div className="relative">
					<div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
						<MagnifyingGlassIcon className="h-5 w-5 text-gray-400"
						                     aria-hidden="true"
						/>
					</div>

					<input
						id="search"
						name="search"
						className="block w-full rounded-md border-0 bg-gray-50 focus:bg-white py-1.5 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-rose-600 sm:text-sm sm:leading-6"
						placeholder="Search"
						type="search"
					/>
				</div>
			</div>}
		</div>
	</MarketingHeader>);
}