import home from '@/domains/docs/routing/home';

const routes = {
	home: {
		title: 'Home',
		href:  '/docs',
		links: home,
	},

	// recruiting: {
	// 	title: 'Recruiting',
	// 	href:  '/docs/recruiting',
	// 	links: recruiting,
	// },

	// security: {
	// 	title: 'Security',
	// 	href:  '/docs/security',
	// 	links: security,
	// },
};

export default routes;