import {Header}             from '@/domains/docs/components/Header';
import {Navigation}         from '@/domains/docs/components/Navigation';
import SimpleFooter         from '@/domains/docs/components/SimpleFooter';
import {TableOfContents}    from '@/domains/docs/components/TableOfContents';
import routes               from '@/domains/docs/routing/routes';
import {Prose}              from '@/domains/shared/components/Prose';
import {slugifyWithCounter} from '@sindresorhus/slugify';
import Head                 from 'next/head';
import {
	useCallback,
	useEffect,
	useRef,
	useState,
}                           from 'react';

function getNodeText(node) {
	let text = '';
	for (let child of node.children ?? []) {
		if (typeof child === 'string') {
			text += child;
		}
		text += getNodeText(child);
	}
	return text;
}

function collectHeadings(nodes, slugify = slugifyWithCounter()) {
	let sections = [];

	for (let node of nodes) {
		if (node.name === 'h2' || node.name === 'h3') {
			let title = getNodeText(node);
			if (title) {
				let id             = slugify(title);
				node.attributes.id = id;
				if (node.name === 'h3') {
					if (!sections[sections.length - 1]) {
						throw new Error('Cannot add `h3` to table of contents without a preceding `h2`');
					}
					sections[sections.length - 1].children.push({
						...node.attributes,
						title,
					});
				} else {
					sections.push({
						...node.attributes,
						title,
						children: [],
					});
				}
			}
		}

		sections.push(...collectHeadings(node.children ?? [], slugify));
	}

	return sections;
}

function useTableOfContents(tableOfContents, scrollableElement, headerElement) {
	let [currentSection, setCurrentSection] = useState(tableOfContents[0]?.id);

	let getHeadings = useCallback((tableOfContents) => {
		return tableOfContents
			.flatMap((node) => [
				node.id,
				...node.children.map((child) => child.id),
			])
			.map((id) => {
				let el = document.getElementById(id);

				if (!el) {
					return;
				}

				let style = window.getComputedStyle(el);

				let scrollMt = parseFloat(style.scrollMarginTop);

				let top = -(headerElement?.current?.offsetHeight + 1) + scrollableElement?.current?.scrollTop + el.getBoundingClientRect().top - scrollMt;

				return {
					id,
					top,
				};
			});
	}, [
		headerElement,
		scrollableElement,
	]);

	useEffect(() => {
		if (tableOfContents.length === 0) {
			return;
		}

		let headings = getHeadings(tableOfContents);

		function onScroll() {
			let top     = scrollableElement.current.scrollTop;
			let current = headings[0].id;

			for (let heading of headings) {
				if (top >= heading.top) {
					current = heading.id;
				} else {
					break;
				}
			}

			setCurrentSection(current);
		}

		scrollableElement.current.addEventListener('scroll', onScroll, {passive: true});

		onScroll();

		let copyOfCurrentScrollableElement = scrollableElement.current;

		return () => {
			copyOfCurrentScrollableElement.removeEventListener('scroll', onScroll);
		};
	}, [
		getHeadings,
		scrollableElement,
		tableOfContents,
	]);

	return currentSection;
}

export default function DocsLayout({
	                                   markdoc,
	                                   children,
                                   }) {
	let headerElement     = useRef();
	let scrollableElement = useRef();

	let title    = markdoc?.frontmatter.title;
	let subtitle = markdoc?.frontmatter?.subtitle;

	let pageTitle = markdoc?.frontmatter.pageTitle || `${markdoc?.frontmatter.title} - RecruitKit Docs`;

	let description = markdoc?.frontmatter.description;

	let tableOfContents = markdoc?.content ? collectHeadings(markdoc.content) : [];

	let currentSection = useTableOfContents(tableOfContents, scrollableElement, headerElement);

	const navigation = routes[markdoc?.frontmatter.navigation].links;

	return (<>
      <Head>
        <title>{pageTitle}</title>

	      {description && <meta name="description"
	                            content={description}
	      />}
      </Head>

      <main className="flex flex-col h-full w-full">

      <Header className="flex-shrink-0 border-b border-gray-200"
              navigation={routes}
              currentNavigation={markdoc?.frontmatter.navigation}
              ref={headerElement}
      />

      <div className="overflow-y-scroll w-full h-screen flex-1 relative mx-auto flex justify-center sm:px-2 lg:px-8 xl:px-12">
        <div className="hidden lg:relative lg:block lg:flex-none">
          <div className="absolute inset-y-0 right-0 w-[50vw] bg-slate-50 dark:hidden" />
          <div className="absolute bottom-0 right-0 top-16 hidden h-12 w-px bg-gradient-to-t from-slate-800 dark:block" />
          <div className="absolute bottom-0 right-0 top-28 hidden w-px bg-slate-800 dark:block" />
          <div className="sticky top-0 -ml-0.5 w-64 overflow-y-auto overflow-x-hidden py-16 pl-0.5 pr-8 xl:w-72 xl:pr-16">
	          <Navigation navigation={navigation} />
          </div>
        </div>

        <div className="min-w-0 max-w-[86rem] flex-auto overflow-y-scroll flex"
             ref={scrollableElement}
        >
	        <div className="min-w-0 max-w-2xl flex-auto px-4 py-16 lg:max-w-8xl lg:pl-8 lg:pr-0 xl:px-16">
		        <article>
			        {(title || subtitle) && (<header className="mb-9 space-y-1">
				        {title && (<h1 className="font-display text-3xl tracking-tight text-slate-900 dark:text-white">
					        {title}
				        </h1>)}

				        {subtitle && (<p className="font-display text-lg font-medium text-slate-500">
					        {subtitle}
				        </p>)}
			        </header>)}

			        <Prose>{children}</Prose>
		        </article>

	            <SimpleFooter />
	        </div>

	        <div className="hidden xl:sticky xl:top-0 xl:-mr-6 xl:block xl:flex-none xl:overflow-y-auto xl:py-16 xl:pl-5 xl:pr-32">
	          <TableOfContents tableOfContents={tableOfContents}
	                           currentSection={currentSection}
	                           className="w-56"
	          />
	        </div>
	      </div>
      </div>
      </main>
    </>);
}
