import {Footer}      from '@/domains/marketing/components/Footer';
import {Header}      from '@/domains/marketing/components/Header';
import classNames    from 'classnames';
import Head          from 'next/head';
import {usePathname} from 'next/navigation';

export default function PrimaryLayout({
	                                      pageTitle,
	                                      description = null,
	                                      ogTitle = null,
	                                      ogDate = null,
	                                      ogCategory = null,
	                                      ogAuthors = [],
	                                      className,
	                                      headerClassName,
	                                      footerClassName,
	                                      children,
	                                      innerRef,
                                      }) {
	const pathname = usePathname();

	const ogImageQueryParameters = [
		{
			key:   'title',
			value: ogTitle ?? pageTitle.split(' |')[0],
		},

		ogDate ? {
			key:   'date',
			value: ogDate,
		} : null,

		ogCategory ? {
			key:   'category',
			value: ogCategory,
		} : null,

		...ogAuthors.map((author) => {
			return {
				key:   'authors[]',
				value: author,
			};
		}),
	]
		.filter((item) => item);

	const encodeQueryParameters = (parameters) => {
		return parameters.map((parameter) => {
			return `${parameter.key}=${encodeURIComponent(parameter.value)}`;
		})
		                 .join('&');
	};

	const url = `https://atlas.dev${pathname}`;
	const ogImageUrl = `https://atlas.dev/api/og?${encodeQueryParameters(ogImageQueryParameters)}`;

	return (<>
      <Head>
	      <title>{pageTitle}</title>

	      {description && <meta name="description"
	                            content={description}
	      />}

	      <meta property="og:url"
	            content={url}
	      />

		  <meta property="og:type"
		        content="website"
		  />

		  <meta property="og:title"
		        content={pageTitle}
		  />

	      {description && <meta property="og:description"
	                            content={description}
	      />}

	      <meta property="og:image"
	            content={ogImageUrl}
	      />

	      <meta property="og:site_name"
	            content="Atlas Software"
	      />

	      <meta name="twitter:card"
	            content="summary_large_image"
	      />

		  <meta property="twitter:domain"
		        content="atlas.dev"
		  />

		  <meta property="twitter:url"
		        content={url}
		  />

		  <meta name="twitter:title"
		        content={pageTitle}
		  />

	      {description && <meta name="twitter:description"
	                            content={description}
	      />}

	      <meta property="twitter:image"
	            content={ogImageUrl}
	      />

	      <meta property="twitter:site"
	            content="@hireatlas"
	      />
      </Head>

      <Header className={headerClassName} />

      <main className={classNames([
	      'relative isolate min-h-[70vh]',
	      className,
      ])}
            ref={innerRef}
      >
	      {children}
      </main>

      <Footer className={footerClassName} />
    </>);
}
