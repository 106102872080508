import classNames from 'classnames';

export default function Blur({
	                             children,
	                             className = 'px-6 py-24 sm:py-48 lg:px-8',
                             }) {
	return (<div className={classNames('relative isolate bg-white overflow-x-clip', className)}>
		<div className="hidden sm:block absolute -z-10 transform-gpu blur-3xl -top-10 right-1/2 mr-10"
		     aria-hidden="true"
		>
			<div
				className="aspect-[2/1] w-screen sm:w-[68.5625rem] bg-gradient-to-tr from-teal-500/5 to-teal-500/25"
				style={{
					clipPath: 'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
				}}
			/>
		</div>

		<div className="hidden sm:block absolute -z-10 transform-gpu blur-3xl -top-10 left-1/2 ml-10"
		     aria-hidden="true"
		>
			<div className="aspect-[2/1] w-screen sm:w-[68.5625rem] bg-gradient-to-tr from-teal-500/25 to-teal-700/40"
			     style={{
				     clipPath: 'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
			     }}
			/>
		</div>

		{children}
	</div>);
}