import classNames from 'classnames';
import {
	useEffect,
	useRef,
}                 from 'react';

export default function Comments({issue}) {
	const ref = useRef(null);

	useEffect(() => {
		const scriptElement = document.createElement('script');

		scriptElement.async       = true;
		scriptElement.crossOrigin = 'anonymous';
		scriptElement.src         = 'https://giscus.app/client.js';

		scriptElement.setAttribute('data-term', issue);
		scriptElement.setAttribute('data-category', 'Announcements');
		scriptElement.setAttribute('data-category-id', 'DIC_kwDOK_vIGs4CeXeh');
		scriptElement.setAttribute('data-repo', 'hireatlas/blog');
		scriptElement.setAttribute('data-theme', 'preferred_color_scheme');
		scriptElement.setAttribute('data-input-position', 'bottom');
		scriptElement.setAttribute('data-strict', '0');
		scriptElement.setAttribute('data-reactions-enabled', '1');
		scriptElement.setAttribute('data-emit-metadata', '0');
		scriptElement.setAttribute('data-mapping', 'specific');

		ref.current?.appendChild(scriptElement);
	}, [
		issue,
	]);

	return <div ref={ref}
	            className={classNames('border-t-2 border-teal-700 pt-8')}
	></div>;
}