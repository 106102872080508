import classNames from 'classnames';
import Link       from 'next/link';

export default function CategoryList({
	                                     categories,
	                                     currentCategorySlug,
	                                     className,
                                     }) {
	const list = [
		{
			href:    '/blog',
			label:   'All',
			current: currentCategorySlug === null,
		},
		...categories.map((category) => {
			return {
				href:    `/blog/category/${category.slug}`,
				label:   category.name,
				current: category.slug === currentCategorySlug,
			};
		}),
	];

	return (<nav className={classNames([
		'flex flex-1 flex-col',
		className,
	])}
	             aria-label="Sidebar"
	>
		<ul role="list"
		    className="space-y-1"
		>
			{list.map((item, index) => (<li key={index}>
				<Link href={item.href}
				      className={classNames([
					      'group pl-4 py-2 text-sm leading-6 font-semibold border-l-2 flex',
					      item.current ? 'border-white text-white hover:text-teal-100 hover:border-teal-100' : 'border-transparent text-teal-50 hover:text-teal-100',
				      ])}
				>
					{item.label}
				</Link>
			</li>))}
		</ul>
	</nav>);
}
