const routes = [
	{
		title: 'Recruiting',
		links: [
			{
				title: 'Overview',
				href:  '/docs/recruiting',
			},
		],
	},
	{
		title: 'Applications',
		links: [
			{
				title: 'Supported providers',
				href:  '/docs/how-to-contribute',
			},
			{
				title: 'Architecture guide',
				href:  '/docs/architecture-guide',
			},
			{
				title: 'Design principles',
				href:  '/docs/design-principles',
			},
		],
	},
	{
		title: 'Onboarding',
		links: [
			{
				title: 'Supported providers',
				href:  '/docs/how-to-contribute',
			},
			{
				title: 'Architecture guide',
				href:  '/docs/architecture-guide',
			},
			{
				title: 'Design principles',
				href:  '/docs/design-principles',
			},
		],
	},
	{
		title: 'Custom Workflows',
		links: [
			{
				title: 'Supported providers',
				href:  '/docs/how-to-contribute',
			},
			{
				title: 'Architecture guide',
				href:  '/docs/architecture-guide',
			},
			{
				title: 'Design principles',
				href:  '/docs/design-principles',
			},
		],
	},
];

export default routes;