import {Disclosure}    from '@headlessui/react';
import {ChevronUpIcon} from '@heroicons/react/20/solid';
import classNames      from 'classnames';

function InnerTableOfContents({
	                              tableOfContents,
	                              currentSection,
	                              className,
                              }) {
	function isActive(section) {
		return section.id === currentSection;
	}

	// These are deliberately `a` tags and not `Link` tags, as using `Link` prevents the page from automatically
	// re-rendering whenever a change is made to the markdown content during development.

	return (<ol role="list"
	            className={classNames('mt-4 space-y-3 text-sm font-medium text-teal-900', className)}
	>
		{tableOfContents.map((section) => (<li key={section.id}>
			<h3 className="-ml-4">
				<a href={`#${section.id}`}
				   className={classNames([
					   'border-l-2 pl-4 inline-block',
					   isActive(section) ? 'text-teal-600 border-teal-500' : 'border-transparent hover:text-teal-600',
				   ])}
				>
					{section.title}
				</a>
			</h3>

			{section.children.length > 0 && (<ol
				role="list"
				className="mt-2 space-y-2 -ml-4"
			>
				{section.children.map((subSection) => (<li key={subSection.id}>
					<a href={`#${subSection.id}`}
					   className={classNames([
						   'border-l-2 pl-8 inline-block',
						   isActive(subSection) ? 'text-teal-600 border-teal-500' : 'border-transparent hover:text-teal-600',
					   ])}
					>
						{subSection.title}
					</a>
				</li>))}
			</ol>)}
		</li>))}
	</ol>);
}

export function TableOfContents({
	                                tableOfContents,
	                                currentSection,
	                                className,
                                }) {

	return (<nav className={className}>
		<Disclosure as="div"
		            className="lg:hidden"
		>
			{({open}) => (<>
              <Disclosure.Button className="font-display text-sm font-semibold text-teal-900 inline-flex justify-between w-full">
                <span>On this page</span>

                <ChevronUpIcon
	                className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-teal-600`}
                />
              </Disclosure.Button>

              <Disclosure.Panel>
				<InnerTableOfContents tableOfContents={tableOfContents}
				                      currentSection={currentSection}
				/>
              </Disclosure.Panel>
            </>)}
		</Disclosure>

		<p className="font-display text-sm font-semibold text-teal-900 hidden lg:block">
			On this page
		</p>

		<InnerTableOfContents className="hidden lg:block"
		                      tableOfContents={tableOfContents}
		                      currentSection={currentSection}
		/>

	</nav>);
}
