import {
	AcademicCapIcon,
	BanknotesIcon,
	ChatBubbleLeftRightIcon,
	CodeBracketIcon,
	PencilSquareIcon,
	ServerStackIcon,
} from '@heroicons/react/24/outline';

export const navigation = {
	services:  {
		items: [
			// {
			// 	name:        'Planning',
			// 	description: 'We\'ll help you plan your upcoming project, making sure it kicks off on the right foot.',
			// 	href:        '/services/planning',
			// 	icon:        PencilSquareIcon,
			// },
			{
				name:        'Development',
				description: 'We can build your Laravel project alongside your team, or we can build it for you. Whatever you need.',
				href:        '/services/development',
				icon:        CodeBracketIcon,
			},
			// {
			// 	name:        'Consulting',
			// 	description: 'Code review, architecture advice, performance optimisation, or one offs. We can do it all, and help you do it too.',
			// 	href:        '/services/consulting',
			// 	icon:        ChatBubbleLeftRightIcon,
			// },
			// {
			// 	name:        'Mentoring',
			// 	description: 'Level up your skills, or those of your team, through dedicated pairing sessions. We can coach juniors to mid-level, and mid-level to seniors.',
			// 	href:        '/services/mentoring',
			// 	icon:        AcademicCapIcon,
			// },
		],

		callsToAction: [
			{
				name:        'Our tech stack',
				description: null,
				href:        '/tech-stack',
				icon:        ServerStackIcon,
			},
			{
				name:        'Paying it forward',
				description: null,
				href:        '/blog/paying-it-forward',
				icon:        BanknotesIcon,
			},
		],
	},

	company:   {
		items: [
			{
				name:        'Our tech stack',
				description: null,
				href:        '/tech-stack',
				icon:        null,
			},
			{
				name:        'Our thoughts',
				description: null,
				href:        '/blog',
				icon:        null,
			},
			// {
			// 	name:        'Our process',
			// 	description: null,
			// 	href:        '/process',
			// 	icon:        null,
			// },
			// {
			// 	name:        'Our pricing',
			// 	description: null,
			// 	href:        '/pricing',
			// 	icon:        null,
			// },
		],
	},

	community: {
		items: [
			{
				name:        'Contact us',
				description: null,
				href:        '/contact-us',
				icon:        null,
			},
			{
				name:        'Paying it forward',
				description: null,
				href:        '/blog/paying-it-forward',
				icon:        null,
			},
			{
				name:        'Hallway Track podcast',
				description: null,
				href:        'https://hallwaytrack.fm/',
				icon:        null,
			},
		],
	},

	social:    {
		items: [
			{
				name: 'X',
				href: 'https://twitter.com/hireatlas',
				icon: (props) => (<svg viewBox="0 0 1200 1227"
				                       fill="currentColor"
				                       {...props}
				>
					<path d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z"
					      transform="scale(0.8)"
					      style={{transformOrigin: '50%'}}
					/>
				</svg>),
			},
			{
				name: 'LinkedIn',
				href: 'https://www.linkedin.com/company/hireatlas/',
				icon: (props) => (<svg fill="currentColor"
				                       viewBox="0 0 24 24"
				                       {...props}>
					<path d="M20.5 2h-17A1.5 1.5 0 002 3.5v17A1.5 1.5 0 003.5 22h17a1.5 1.5 0 001.5-1.5v-17A1.5 1.5 0 0020.5 2zM8 19H5v-9h3zM6.5 8.25A1.75 1.75 0 118.3 6.5a1.78 1.78 0 01-1.8 1.75zM19 19h-3v-4.74c0-1.42-.6-1.93-1.38-1.93A1.74 1.74 0 0013 14.19a.66.66 0 000 .14V19h-3v-9h2.9v1.3a3.11 3.11 0 012.7-1.4c1.55 0 3.36.86 3.36 3.66z"></path>

				</svg>),
			},
			{
				name: 'GitHub',
				href: 'https://github.com/hireatlas',
				icon: (props) => (<svg fill="currentColor"
				                       viewBox="0 0 24 24"
				                       {...props}>
					<path
						fillRule="evenodd"
						d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
						clipRule="evenodd"
					/>
				</svg>),
			},
		],
	},
};