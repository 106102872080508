import {motion} from 'framer-motion';

const themes = {
	default: {
		text: '#231F20',
		icon: '#0D9488',
	},

	white: {
		text: '#FFFFFF',
		icon: '#FFFFFF',
	},
};

export default function Logo({
	                             theme = 'default',
	                             animation = false,
	                             ...props
                             }) {
	const textColor = themes[theme].text;
	const iconColor = themes[theme].icon;

	const letterPaths = [
		'M133.2,58.9v15.7h-5.9v-3.3c-1.5,2.3-4.4,3.6-8.4,3.6c-6.1,0-10-3.4-10-8c0-4.5,3-8,11.1-8h6.9v-0.4 c0-3.7-2.2-5.8-6.7-5.8c-3,0-6.1,1-8.1,2.7l-2.5-4.6c2.9-2.2,7-3.3,11.3-3.3C128.7,47.5,133.2,51.2,133.2,58.9z M126.9,66.2v-3.1 h-6.5c-4.3,0-5.4,1.6-5.4,3.6c0,2.3,1.9,3.7,5.1,3.7C123.2,70.3,125.9,68.9,126.9,66.2z',
		'M157.3,73.1c-1.5,1.2-3.8,1.8-6,1.8c-5.9,0-9.2-3.1-9.2-9V53h-4.5v-5h4.4v-6.1h6.3V48h7.2v5h-7.2v12.7 c0,2.6,1.3,4,3.7,4c1.3,0,2.6-0.4,3.5-1.1L157.3,73.1z',
		'M162.4,37.4h6.3v37.1h-6.3V37.4z',
		'M199.2,58.9v15.7h-5.9v-3.3c-1.5,2.3-4.4,3.6-8.4,3.6c-6.1,0-10-3.4-10-8c0-4.5,3-8,11.1-8h6.9v-0.4 c0-3.7-2.2-5.8-6.7-5.8c-3,0-6.1,1-8.1,2.7l-2.5-4.6c2.9-2.2,7-3.3,11.3-3.3C194.8,47.5,199.2,51.2,199.2,58.9z M193,66.2v-3.1 h-6.5c-4.3,0-5.4,1.6-5.4,3.6c0,2.3,1.9,3.7,5.1,3.7C189.3,70.3,191.9,68.9,193,66.2z',
		'M204.2,71.9l2.4-4.8c2.4,1.6,6,2.7,9.3,2.7c4,0,5.6-1.1,5.6-3c0-5.1-16.5-0.3-16.5-11c0-5.1,4.6-8.4,11.8-8.4 c3.6,0,7.6,0.9,10,2.3l-2.4,4.8c-2.6-1.5-5.1-2-7.7-2c-3.8,0-5.6,1.3-5.6,3c0,5.4,16.5,0.6,16.5,11.1c0,5-4.6,8.3-12.1,8.3 C211,74.9,206.6,73.6,204.2,71.9z',
		'M241.7,71.9l2.4-4.8c2.4,1.6,6,2.7,9.3,2.7c4,0,5.6-1.1,5.6-3c0-5.1-16.5-0.3-16.5-11c0-5.1,4.6-8.4,11.8-8.4 c3.6,0,7.6,0.9,10,2.3l-2.4,4.8c-2.6-1.5-5.1-2-7.7-2c-3.8,0-5.6,1.3-5.6,3c0,5.4,16.5,0.6,16.5,11.1c0,5-4.6,8.3-12.1,8.3 C248.6,74.9,244.1,73.6,241.7,71.9z',
		'M268.4,61.2c0-8,6-13.7,14.2-13.7c8.3,0,14.3,5.7,14.3,13.7s-6,13.7-14.3,13.7 C274.4,74.9,268.4,69.2,268.4,61.2z M290.5,61.2c0-5.1-3.4-8.4-8-8.4c-4.5,0-7.9,3.3-7.9,8.4s3.4,8.4,7.9,8.4 C287.1,69.5,290.5,66.3,290.5,61.2z',
		'M309.7,46.4V48h7.4v5h-7.2v21.5h-6.3V53h-4.4v-5h4.4v-1.7c0-5.6,3.4-9.3,9.6-9.3c2.2,0,4.3,0.5,5.7,1.5 l-1.8,4.7c-1-0.7-2.2-1.1-3.5-1.1C311.1,42.1,309.7,43.6,309.7,46.4z',
		'M340.1,73.1c-1.5,1.2-3.8,1.8-6,1.8c-5.9,0-9.2-3.1-9.2-9V53h-4.4v-5h4.4v-6.1h6.3V48h7.2v5h-7.2v12.7 c0,2.6,1.3,4,3.7,4c1.3,0,2.6-0.4,3.5-1.1L340.1,73.1z',
		'M386.9,47.8L377,74.5h-6l-6.9-18.4l-7,18.4h-6l-9.8-26.7h5.9l7.1,19.7l7.4-19.7h5.3l7.2,19.8l7.3-19.8H386.9z',
		'M413.1,58.9v15.7h-5.9v-3.3c-1.5,2.3-4.4,3.6-8.4,3.6c-6.1,0-10-3.4-10-8c0-4.5,3-8,11.1-8h6.9v-0.4 c0-3.7-2.2-5.8-6.7-5.8c-3,0-6.1,1-8.1,2.7l-2.5-4.6c2.9-2.2,7-3.3,11.3-3.3C408.6,47.5,413.1,51.2,413.1,58.9z M406.8,66.2v-3.1 h-6.5c-4.3,0-5.4,1.6-5.4,3.6c0,2.3,1.9,3.7,5.1,3.7C403.1,70.3,405.8,68.9,406.8,66.2z',
		'M434.5,47.5v6c-0.5-0.1-1-0.1-1.5-0.1c-4.8,0-7.8,2.8-7.8,8.3v13h-6.3V47.8h6v3.9 C426.8,48.9,430.1,47.5,434.5,47.5z',
		'M463.8,63.2h-21c0.8,3.9,4,6.4,8.7,6.4c3,0,5.4-1,7.3-2.9l3.4,3.9c-2.4,2.9-6.2,4.4-10.8,4.4 c-9,0-14.8-5.8-14.8-13.7s5.9-13.7,13.8-13.7c7.8,0,13.5,5.5,13.5,13.8C463.9,61.8,463.9,62.6,463.8,63.2z M442.8,59H458 c-0.5-3.9-3.5-6.5-7.6-6.5C446.4,52.5,443.4,55.1,442.8,59z',
	];

	const letterTiming = {
		duration: 2,
		times:    [
			0.25,
			0.31,
			0.37,
			0.42,
			0.48,
			0.54,
			0.60,
			0.65,
			0.71,
			0.77,
			0.83,
			0.88,
			0.94,
			1.00,
		],
	};

	return (<motion.svg
		initial="static"
		animate="static"
		whileHover="animating"
		viewBox="0 0 463.9 87.2"
		{...props}
	>
		{letterPaths.map((letterPath, index) => {
			return (<motion.path key={letterPath}
			                     fill={textColor}
			                     d={letterPath}
			                     variants={{
				                     static:    {
					                     opacity:    1,
					                     transition: {
						                     delay:    0.5 + index * 0.025,
						                     duration: 0.5,
					                     },
				                     },
				                     animating: {
					                     opacity:    0,
					                     transition: {
						                     delay:    0,
						                     duration: 0.01,
					                     },
				                     },
			                     }}
			/>);
		})}

		<g style={{transform: 'translateX(-3rem)'}}>
			{letterPaths.map((letterPath, index) => {
				return (<motion.path key={letterPath}
				                     fill={textColor}
				                     d={letterPath}
				                     variants={{
					                     static:    {
						                     opacity:    0,
						                     transition: {
							                     delay:    0,
							                     duration: 0,
						                     },
					                     },
					                     animating: {
						                     opacity:    1,
						                     transition: {
							                     delay:    letterTiming.duration * letterTiming.times[index],
							                     duration: 0.2,
						                     },
					                     },
				                     }}
				/>);
			})}
		</g>

		<motion.path fill={iconColor}
		             d="M0 81.4h84.7v5.8H0z"
		             variants={{
			             static:    {
				             scaleX:     1,
				             translateX: 0,
				             transition: {
					             duration: 0.5,
				             },
			             },
			             animating: {
				             scaleX:     [
					             1,
					             0.35,
					             0.35,
					             0.35,
					             0.35,
					             0.35,
					             0.35,
					             0.35,
					             0.35,
					             0.35,
					             0.35,
					             0.35,
					             0.35,
					             0.35,
					             0.35,
				             ],
				             translateX: [
					             '0%',
					             '7%',
					             '15%',
					             '21%',
					             '25%',
					             '30%',
					             '40%',
					             '45%',
					             '50%',
					             '55%',
					             '60%',
					             '70%',
					             '77%',
					             '83%',
					             '86%',
				             ],
				             transition: {
					             duration: letterTiming.duration,
					             times:    [
						             0.0,
						             ...letterTiming.times,
					             ],
				             },
			             },
		             }}
		/>

		<motion.path fill={iconColor}
		             d="M84.7 74.9H63.5L42.3 37.4 21.1 74.9H0L42.3 0z"
		             variants={{
			             static:    {
				             scaleX:     1,
				             scaleY:     1,
				             rotate:     0,
				             translateX: 0,
				             translateY: 0,
				             transition: {
					             duration: 0.4,
				             },
			             },
			             animating: {
				             scaleX:     0.5,
				             scaleY:     0.5,
				             rotate:     90,
				             translateX: '-1rem',
				             translateY: '1.5rem',
				             transition: {
					             duration: 0.4,
				             },
			             },
		             }}
		/>

		<motion.path fill={iconColor}
		             d="M54.3 74.9h-24l12-21.2z"
		             variants={{
			             static:    {
				             opacity:    1,
				             transition: {
					             delay:    0.5,
					             duration: 0.25,
				             },
			             },
			             animating: {
				             opacity:    0,
				             transition: {
					             duration: 0.25,
				             },
			             },
		             }}
		/>
	</motion.svg>);
}
