import {Button} from '@/domains/shared/components/Button';
import {ArrowRightMovingOnHoverIcon} from '@/domains/shared/components/icons/ArrowRightMovingOnHoverIcon';

export default function MailingList() {
	return (<div className="relative isolate overflow-hidden bg-teal-700 px-8 py-16 shadow-2xl rounded-3xl">
		<h2 className="mx-auto max-w-3xl text-center text-3xl font-bold tracking-tight text-white sm:text-4xl">
			Want to receive updates from us?
		</h2>

		<p className="mx-auto mt-2 max-w-xl text-center text-balance text-lg leading-8 text-teal-100">
			Sign up for our newsletter to stay up to date with Laravel, Inertia, and Expo development.
		</p>

		<form className="mx-auto mt-10 flex max-w-md gap-x-4"
		      action="https://hireatlas.mailcoach.app/subscribe/ddaa483f-a15a-49b1-b3f0-94147725779c"
		      method="post"
		>
			<label htmlFor="email-address"
			       className="sr-only"
			>
				Email address
			</label>

			<input
				id="email-address"
				name="email"
				type="email"
				autoComplete="email"
				required
				className="min-w-0 flex-auto rounded-md border-0 bg-teal-50/90 px-3.5 py-2 text-teal-900 placeholder:text-teal-900 shadow-sm ring-1 ring-inset ring-teal-50/10 focus:ring-2 focus:ring-inset focus:ring-teal-50 sm:text-sm sm:leading-6"
				placeholder="Enter your email"
			/>

			<div className="hidden">
				<label htmlFor="angle">What&apos;s your angle?</label>

				<input type="text"
				       id="angle"
				       name="angle"
				/>
			</div>

			<Button type="submit"
			        color="white"
			>
				Notify me

				<ArrowRightMovingOnHoverIcon />
			</Button>
		</form>

		<svg
			viewBox="0 0 1024 1024"
			className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2"
			aria-hidden="true"
		>
			<circle cx={512}
			        cy={512}
			        r={512}
			        fill="url(#759c1415-0410-454c-8f7c-9a820de03641)"
			        fillOpacity="0.7"
			/>
			<defs>
				<radialGradient
					id="759c1415-0410-454c-8f7c-9a820de03641"
					cx={0}
					cy={0}
					r={1}
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(512 512) rotate(90) scale(512)"
				>
					<stop stopColor="#0f766e" />
					<stop offset={1}
					      stopColor="#ccfbf1"
					      stopOpacity={0}
					/>
				</radialGradient>
			</defs>
		</svg>
	</div>);
}