import classNames from 'classnames';
import Link       from 'next/link';
import React      from 'react';

export default function Header({
	                               categorySlug = null,
	                               categoryName = null,
	                               innerRef = null,
	                               className = '',
	                               h1 = false,
                               }) {
	const HeaderTag = h1 ? 'h1' : 'h2';

	return (
		<HeaderTag className={classNames('text-3xl font-bold tracking-tight text-gray-900 gap-x-2 inline-flex flex-wrap', className)}
		           ref={innerRef}
		>
			{!categoryName && <Link href="/blog">our thoughts</Link>}

			{categoryName && <Link href={`/blog/category/${categorySlug}`}
			                       className="text-teal-600"
			>{categoryName}:</Link>}

			{categoryName && <Link href="/blog">our thoughts</Link>}
		</HeaderTag>);
}
