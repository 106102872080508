import {Container}  from '@/domains/marketing/components/Container';
import {Button}     from '@/domains/shared/components/Button';
import {ArrowRightMovingOnHoverIcon} from '@/domains/shared/components/icons/ArrowRightMovingOnHoverIcon';
import Logo         from '@/domains/shared/components/Logo';
import {navigation} from '@/domains/shared/navigation';
import {
	EnvelopeIcon,
	PhoneIcon,
}                   from '@heroicons/react/24/solid';
import classNames   from 'classnames';
import Link         from 'next/link';

export function Footer({className = 'bg-teal-600'}) {
	return (<footer className={classNames([
		className,
		'isolate',
	])}
	                aria-labelledby="footer-heading"
	>
		<h2 id="footer-heading"
		    className="sr-only"
		>
			Footer
		</h2>

		<Container className="pb-8 pt-12 space-y-8 divide-y divide-teal-100/50">
			<div className="xl:grid xl:grid-cols-5 xl:gap-8">
				<div className="xl:col-span-2 space-y-5">
					<Link href="/"
					      className="inline-block"
					>
						<Logo theme="white"
						      animation={true}
						      className="h-16"
						/>
					</Link>

					<div className="max-w-sm text-sm leading-6 text-teal-50 space-y-2">
						<p className="flex gap-x-2 items-center">
							We love building great software.
						</p>

						<div className="flex gap-x-4 items-center">
							<p className="flex gap-x-2 items-center">
								<EnvelopeIcon className="h-5 w-5" />

								<a href="mailto:hey@atlas.dev"
								   className="hover:underline"
								>hey@atlas.dev</a>
							</p>

							<p className="flex gap-x-2 items-center">
								<PhoneIcon className="h-5 w-5" />

								<a href="tel:+61499246622"
								   className="hover:underline"
								>0499 24 66 22</a>
							</p>
						</div>
					</div>
				</div>

				<div className="mt-16 grid md:grid-cols-3 gap-8 xl:col-span-3 xl:mt-10">
					<div>
						<h3 className="text-sm font-bold leading-6 text-white">Services</h3>
						<ul role="list"
						    className="mt-5 space-y-2"
						>
							{navigation.services.items.map((item) => (<li key={item.name}>
								<Link href={item.href}
								      className="text-sm leading-6 text-teal-50 hover:text-white focus:text-white"
								>
									{item.name}
								</Link>
							</li>))}
						</ul>
					</div>
					<div className="mt-10 md:mt-0">
						<h3 className="text-sm font-bold leading-6 text-white">About us</h3>
						<ul role="list"
						    className="mt-5 space-y-2"
						>
							{navigation.company.items.map((item) => (<li key={item.name}>
								<Link href={item.href}
								      className="text-sm leading-6 text-teal-50 hover:text-white focus:text-white"
								>
									{item.name}
								</Link>
							</li>))}
						</ul>
					</div>
					<div className="mt-10 md:mt-0">
						<h3 className="text-sm font-bold leading-6 text-white">Community</h3>
						<ul role="list"
						    className="mt-5 space-y-2"
						>
							{navigation.community.items.map((item) => (<li key={item.name}>
								<Link href={item.href}
								      className="text-sm leading-6 text-teal-50 hover:text-white focus:text-white"
								>
									{item.name}
								</Link>
							</li>))}
						</ul>
					</div>
				</div>
			</div>

			<div className="pt-8 lg:flex lg:items-center lg:justify-between">
				<div className="lg:flex-1">
					<h3 className="text-sm font-bold leading-6 text-white">
						Subscribe to our newsletter
					</h3>

					<p className="mt-2 text-sm leading-6 text-teal-50">
						The latest articles and resources, sent to your inbox monthly.
					</p>
				</div>

				<form className="mt-6 sm:flex sm:max-w-md lg:mt-0"
				      action="https://hireatlas.mailcoach.app/subscribe/ddaa483f-a15a-49b1-b3f0-94147725779c"
				      method="post"
				>
					<label htmlFor="email-address"
					       className="sr-only"
					>
						Email address
					</label>
					<input
						id="email-address"
						name="email"
						type="email"
						autoComplete="off"
						required
						className="min-w-0 flex-auto rounded-md border-0 bg-teal-50/90 px-3.5 py-2 text-teal-900 placeholder:text-teal-700 shadow-sm ring-1 ring-inset ring-teal-50/10 focus:ring-2 focus:ring-inset focus:ring-teal-50 sm:text-sm sm:leading-6"
						placeholder="Enter your email"
					/>

					<div className="hidden">
						<label htmlFor="angle">What&apos;s your angle?</label>

						<input type="text"
						       id="angle"
						       name="angle"
						/>
					</div>

					<div className="mt-4 sm:ml-4 sm:mt-0 sm:flex-shrink-0">
						<Button type="submit"
						        color="white"
						>
							Subscribe

							<ArrowRightMovingOnHoverIcon />
						</Button>
					</div>
				</form>
			</div>

			<div className="pt-8 md:flex md:items-center md:justify-between">
				<div className="flex space-x-6 md:order-2">
					{navigation.social.items.map((item) => (<a key={item.name}
					                                           href={item.href}
					                                           target="_blank"
					                                           rel="noreferrer"
					                                           className="text-teal-50 hover:text-white focus:text-white"
					>
						<span className="sr-only">{item.name}</span>

						<item.icon className="h-6 w-6"
						           aria-hidden="true"
						/>
					</a>))}
				</div>

				<div className="mt-8 md:order-1 md:mt-0 flex gap-3 flex-wrap">
					<p className="text-sm leading-6 text-teal-50">
						&copy; {new Date().getFullYear()} Atlas Software Pty Ltd. All rights reserved.
					</p>
				</div>
			</div>
		</Container>
	</footer>);
}
