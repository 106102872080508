import {Container}       from '@/domains/marketing/components/Container';
import {Button}          from '@/domains/shared/components/Button';
import Logo              from '@/domains/shared/components/Logo';
import {navigation}      from '@/domains/shared/navigation';
import {
	Popover,
	Transition,
}                        from '@headlessui/react';
import {ChevronDownIcon} from '@heroicons/react/20/solid';
import classNames        from 'classnames';
import clsx              from 'clsx';
import Link              from 'next/link';
import {Fragment}        from 'react';

function NavLink({
	                 href,
	                 children,
                 }) {
	return (<Link
		href={href}
		className="inline-block rounded-lg px-2 py-1 text-sm font-semibold leading-6 text-slate-700 hover:text-teal-600 focus:text-teal-600 focus-visible:outline-teal-600 select-none"
	>
		{children}
	</Link>);
}

function FlyoutNavLink({
	                       title,
	                       children,
                       }) {
	return (<Popover className="relative">
		<Popover.Button className="inline-flex items-center gap-x-1 rounded-lg px-2 py-1 text-sm font-semibold leading-6 text-slate-700 hover:text-teal-600 focus:text-teal-600 focus-visible:outline-teal-600 select-none">
			<span>{title}</span>
			<ChevronDownIcon className="h-5 w-5"
			                 aria-hidden="true"
			/>
		</Popover.Button>

		<Transition
			as={Fragment}
			enter="transition ease-out duration-200"
			enterFrom="opacity-0 translate-y-1"
			enterTo="opacity-100 translate-y-0"
			leave="transition ease-in duration-150"
			leaveFrom="opacity-100 translate-y-0"
			leaveTo="opacity-0 translate-y-1"
		>
			<Popover.Panel className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4">
				<div className="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
					{children}
				</div>
			</Popover.Panel>
		</Transition>
	</Popover>);
}

function MobileNavLink({
	                       href,
	                       children,
	                       className,
                       }) {
	return (<Popover.Button as={Link}
	                        href={href}
	                        className={classNames([
		                        'block w-full p-2 select-none',
		                        className,
	                        ])}
	>
		{children}
	</Popover.Button>);
}

function MobileNavIcon({open}) {
	return (<svg
		aria-hidden="true"
		className="h-3.5 w-3.5 overflow-visible stroke-slate-700"
		fill="none"
		strokeWidth={2}
		strokeLinecap="round"
	>
		<path
			d="M0 1H14M0 7H14M0 13H14"
			className={clsx('origin-center transition', open && 'scale-90 opacity-0')}
		/>
		<path
			d="M2 2L12 12M12 2L2 12"
			className={clsx('origin-center transition', !open && 'scale-90 opacity-0')}
		/>
	</svg>);
}

function MobileNavigation() {
	return (<Popover>
		<Popover.Button
			className="relative z-10 flex h-8 w-8 items-center justify-center [&:not(:focus-visible)]:focus:outline-none"
			aria-label="Toggle Navigation"
		>
			{({open}) => <MobileNavIcon open={open} />}
		</Popover.Button>
		<Transition.Root>
			<Transition.Child
				as={Fragment}
				enter="duration-150 ease-out"
				enterFrom="opacity-0"
				enterTo="opacity-100"
				leave="duration-150 ease-in"
				leaveFrom="opacity-100"
				leaveTo="opacity-0"
			>
				<Popover.Overlay className="fixed inset-0 bg-slate-300/50" />
			</Transition.Child>

			<Transition.Child
				as={Fragment}
				enter="duration-150 ease-out"
				enterFrom="opacity-0 scale-95"
				enterTo="opacity-100 scale-100"
				leave="duration-100 ease-in"
				leaveFrom="opacity-100 scale-100"
				leaveTo="opacity-0 scale-95"
			>
				<Popover.Panel
					as="div"
					className="absolute inset-x-0 top-full mt-4 flex origin-top flex-col rounded-2xl bg-white p-4 text-lg tracking-tight text-slate-900 shadow-xl ring-1 ring-slate-900/5"
				>
					<MobileNavLink href="/services">Our services</MobileNavLink>
					{navigation.services.items.map((item) => {
						return (<MobileNavLink key={item.href}
						                       className="ml-4"
						                       href={item.href}
						>{item.name}</MobileNavLink>);
					})}
					<hr className="m-2 border-slate-300/40" />
					{navigation.company.items.map((item) => {
						return (<MobileNavLink key={item.href}
						                       href={item.href}
						>{item.name}</MobileNavLink>);
					})}
					<hr className="m-2 border-slate-300/40" />
					{navigation.community.items.map((item) => {
						return (<MobileNavLink key={item.href}
						                       href={item.href}
						>{item.name}</MobileNavLink>);
					})}
					<hr className="m-2 border-slate-300/40" />
					<MobileNavLink href="/contact-us">Contact us</MobileNavLink>
				</Popover.Panel>
			</Transition.Child>
		</Transition.Root>
	</Popover>);
}

export function Header({
	                       className,
	                       children,
                       }) {
	return (<header className={className}>
		<Container className="space-y-3">
			<nav className={classNames([
				'relative z-50 flex justify-between items-center gap-x-4 pt-3 pb-3 lg:pb-0',
				children ? '' : 'lg:pb-3',
			])}
			>
				<Link href="/"
				      aria-label="Home"
				      className="inline-block focus-visible:outline-teal-600 px-4 -mx-4 group"
				>
					<Logo className="h-20 py-4"
					      animation={true}
					/>
				</Link>

				<div className="flex-1 hidden md:flex justify-end items-center gap-x-4 md:gap-x-8">
					<FlyoutNavLink title="our services">
						<div className="p-4">
							{navigation.services.items.map((item) => (<div key={item.name}
							                                               className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50"
							>
								<div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-teal-600 group-hover:bg-teal-500">
									<item.icon className="h-6 w-6 text-white group-hover:text-teal-50"
									           aria-hidden="true"
									/>
								</div>

								<div>
									<a href={item.href}
									   className="font-semibold text-gray-900 focus-visible:outline-teal-600"
									>
										{item.name}
										<span className="absolute inset-0" />
									</a>
									<p className="mt-1 text-gray-600">{item.description}</p>
								</div>
							</div>))}
						</div>
						<div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
							{navigation.services.callsToAction.map((item) => (<a
								key={item.name}
								href={item.href}
								className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100 focus-visible:outline-teal-600"
							>
								<item.icon className="h-5 w-5 flex-none text-gray-400"
								           aria-hidden="true"
								/>
								{item.name}
							</a>))}
						</div>
					</FlyoutNavLink>

					{/*<NavLink href="/process">*/}
					{/*	our process*/}
					{/*</NavLink>*/}

					<NavLink href="/blog">
						our thoughts
					</NavLink>

					{/*<NavLink href="/pricing">*/}
					{/*	our pricing*/}
					{/*</NavLink>*/}

					{/*<NavLink href="/about-us">*/}
					{/*	About us*/}
					{/*</NavLink>*/}

					<Button color="teal"
					        href="/contact-us"
					>
						contact us
					</Button>
				</div>

				<div className="-mr-1 md:hidden">
					<MobileNavigation />
				</div>
			</nav>

			{children}
		</Container>
	</header>);
}
