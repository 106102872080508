import CategoryList         from '@/domains/blog/components/CategoryList';
import Comments             from '@/domains/blog/components/Comments';
import Header               from '@/domains/blog/components/Header';
import {TableOfContents}    from '@/domains/blog/components/TableOfContents';
import articlesDatabase     from '@/domains/blog/databases/articles.json';
import authorsDatabase      from '@/domains/blog/databases/authors.json';
import categoriesDatabase   from '@/domains/blog/databases/categories.json';
import Blur                 from '@/domains/marketing/components/Backgrounds/Blur';
import {Container}          from '@/domains/marketing/components/Container';
import PrimaryLayout        from '@/domains/marketing/layouts/PrimaryLayout';
import MailingList          from '@/domains/shared/components/MailingList';
import {Prose}              from '@/domains/shared/components/Prose';
import {ClockIcon}          from '@heroicons/react/20/solid';
import {slugifyWithCounter} from '@sindresorhus/slugify';
import classNames           from 'classnames';
import Image                from 'next/image';
import {
	useCallback,
	useEffect,
	useRef,
	useState,
}                           from 'react';


function getNodeText(node) {
	let text = '';

	for (let child of node.children ?? []) {
		if (typeof child === 'string') {
			text += ' ' + child;
		}
		text += ' ' + getNodeText(child);
	}

	return text;
}

function collectHeadings(nodes, slugify = slugifyWithCounter()) {
	let sections = [];

	for (let node of nodes) {
		if (node.name === 'h2' || node.name === 'h3') {
			let title = getNodeText(node);
			if (title) {
				let id             = slugify(title);
				node.attributes.id = id;
				if (node.name === 'h3') {
					if (!sections[sections.length - 1]) {
						throw new Error('Cannot add `h3` to table of contents without a preceding `h2`');
					}
					sections[sections.length - 1].children.push({
						...node.attributes,
						title,
					});
				} else {
					sections.push({
						...node.attributes,
						title,
						children: [],
					});
				}
			}
		}

		sections.push(...collectHeadings(node.children ?? [], slugify));
	}

	return sections;
}

function useTableOfContents(tableOfContents, scrollableElement, headerElement) {
	let [currentSection, setCurrentSection] = useState(tableOfContents[0]?.id);

	let getHeadings = useCallback((tableOfContents) => {
		return tableOfContents
			.flatMap((node) => [
				node.id,
				...node.children.map((child) => child.id),
			])
			.map((id) => {
				let el = document.getElementById(id);

				if (!el) {
					return;
				}

				let style = window.getComputedStyle(el);

				let scrollMt = parseFloat(style.scrollMarginTop);

				let top = -(headerElement?.current?.offsetHeight + 1) + document.scrollingElement.scrollTop + el.getBoundingClientRect().top - scrollMt;

				return {
					id,
					top,
				};
			});
	}, [
		headerElement,
	]);

	useEffect(() => {
		if (tableOfContents.length === 0) {
			return;
		}

		let headings = getHeadings(tableOfContents);

		function onScroll() {
			let top     = document.scrollingElement.scrollTop;
			let current = headings[0].id;

			for (let heading of headings) {
				if (top >= heading.top) {
					current = heading.id;
				} else {
					break;
				}
			}

			setCurrentSection(current);
		}

		document.addEventListener('scroll', onScroll, {passive: true});

		onScroll();

		return () => {
			document.removeEventListener('scroll', onScroll);
		};
	}, [
		getHeadings,
		scrollableElement,
		tableOfContents,
	]);

	return currentSection;
}

export default function BlogArticleLayout({
	                                          markdoc,
	                                          children,
                                          }) {
	let headerElement       = useRef();
	let stickyHeaderElement = useRef();
	let scrollableElement   = useRef();

	let slug = markdoc.file.path.substring(6); // Remove '/blog/' from the path

	slug = slug.substring(0, slug.length - 3); // Remove '.md' from the path

	const article = articlesDatabase.find((candidate) => {
		return candidate.slug === slug;
	});

	let title    = article?.title;
	let subtitle = article?.subtitle;

	let pageTitle = article?.pageTitle || `${article?.title} - Atlas Software`;

	let description = article?.description;
	let category    = categoriesDatabase[article?.category] ?? null;

	const authors = article?.authors?.map((author) => {
		return authorsDatabase[author];
	}) ?? [];

	let tableOfContents = markdoc?.content ? [
		{
			id:       'top',
			title:    title,
			children: [],
		},
		...collectHeadings(markdoc.content),
	] : [];

	let currentSection = useTableOfContents(tableOfContents, scrollableElement, headerElement);

	const observer = useRef();

	useEffect(() => {
		observer.current = new IntersectionObserver(([e]) => {
			stickyHeaderElement.current.classList.toggle('hidden', e.isIntersecting);
		}, {threshold: [0.1]});

		return () => {
			observer.current.disconnect();
		};
	}, [
		stickyHeaderElement,
	]);

	useEffect(() => {
		if (headerElement?.current && observer?.current) {
			observer.current.observe(headerElement.current);
		}
	}, [
		observer,
		headerElement,
		stickyHeaderElement,
	]);

	const [readTime, setReadTime] = useState('Checking read time...');

	useEffect(() => {
		if (markdoc?.content) {
			const words = getNodeText({
				children: markdoc.content,
			})
				.replace(/\s\s+/g, ' ')
				.trim()
				.split(' ').length;

			const wordsPerSecond = 3.75;

			const seconds = Math.ceil(words / wordsPerSecond);
			const minutes = Math.ceil(seconds / 60);

			if (minutes === 1) {
				setReadTime(`${minutes} minute`);
			} else {
				setReadTime(`${minutes} minutes`);
			}
		}
	}, [
		markdoc,
	]);

	return (<PrimaryLayout pageTitle={pageTitle}
	                       description={description}
	                       ogTitle={article?.title}
	                       ogDate={article?.date}
	                       ogCategory={category?.name}
	                       ogAuthors={authors.map((author) => author.name)}
	>
		<Blur className="py-12">
			<Container className="isolate">
				<div className="lg:mx-0 lg:max-w-none grid gap-8"
				     key={children}
				>
					<Header categorySlug={category?.slug}
					        categoryName={category?.name}
					        innerRef={headerElement}
					/>

					<div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4 lg:items-start">
						<div className="flex flex-col justify-start gap-8 backdrop-blur backdrop-opacity-20 lg:sticky lg:top-8 lg:max-h-screen lg:pb-16">
							<Header categorySlug={category?.slug}
							        categoryName={category?.name}
							        innerRef={stickyHeaderElement}
							        className="hidden"
							/>

							<CategoryList categories={Object.values(categoriesDatabase)}
							              currentCategorySlug={article?.category}
							              className="bg-teal-700 rounded-2xl p-4"
							/>

							<TableOfContents tableOfContents={tableOfContents}
							                 currentSection={currentSection}
							                 className="p-8 bg-teal-100/25 border-2 border-teal-600 rounded-2xl overflow-y-auto"
							/>
						</div>

						<div className="relative lg:col-span-3">
							<article className="z-10 backdrop-blur backdrop-opacity-50 space-y-8 isolate scroll-mt-8"
							         ref={scrollableElement}
							         id="top"
							>
								{(title || subtitle) && (<header className="mb-8 space-y-1">
									{title && (
										<h1 className="font-display font-bold text-3xl tracking-tight text-slate-900 max-w-5xl text-balance sm:text-wrap"
										>
											{title}
										</h1>)}

									{subtitle && (
										<p className="font-display text-lg font-medium text-slate-500 text-balance">
											{subtitle}
										</p>)}
								</header>)}

								<div className="flex justify-between items-center gap-x-4">
									<div className="relative flex items-center gap-x-4">
										<div className="flex -space-x-2 overflow-hidden flex-shrink-0">
											{authors.map((author, index) => {
												return (
													<span className="ring ring-inset ring-teal-600 rounded-full h-11 w-11 inline-flex items-center justify-center"
													      key={index}
													>
														<Image className="inline-block h-10 w-10 rounded-full"
														       src={author.avatar}
														       width={40}
														       height={40}
														       alt={author.name}
														/>
													</span>);
											})}
										</div>

										{authors.length === 1 && <div className="text-sm leading-6">
											<a href={authors[0].href}
											   className="font-semibold text-gray-900"
											>
												<span className="absolute inset-0" />

												{authors[0].name}
											</a>

											<p className="text-teal-600 font-medium">{authors[0].role}</p>
										</div>}

										{authors.length > 1 && <div className="text-sm leading-6">
											<p className="font-semibold text-gray-900">
												<span className="absolute inset-0" />

												{authors.slice(0, -1)
												        .map((author) => {
													        return author.name;
												        })
												        .join(', ')}
												{` & ${authors[authors.length - 1].name}`}
											</p>
										</div>}
									</div>

									<div className="flex-shrink-0 flex flex-col items-end gap-x-4 text-sm">
										<time dateTime={article?.datetime}
										      title={article?.datetime}
										      className="text-teal-700 font-semibold"
										>
											{article?.date}
										</time>

										<div className="inline-flex items-center gap-x-1 text-teal-900">
											<ClockIcon className="h-4 w-4" /> {readTime}
										</div>
									</div>
								</div>

								{article?.image && <Image className={classNames([
									'h-full w-full bg-gray-50 object-cover rounded-4xl',
								])}
								                          src={article?.image}
								                          width={1500}
								                          height={1500}
								                          alt={article?.title}
								                          priority={true}
								/>}

								<Prose className="lg:text-justify">{children}</Prose>

								<MailingList />

								{article?.issue && <Comments issue={article?.issue} />}
							</article>
						</div>
					</div>
				</div>
			</Container>
		</Blur>
	</PrimaryLayout>);
}
