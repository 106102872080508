import BlogArticleLayout from '@/domains/blog/layouts/BlogArticleLayout';
import DocsLayout        from '@/domains/docs/layouts/DocsLayout';
import '@/styles/tailwind.css';
import * as Fathom       from 'fathom-client';
import 'focus-visible';
import {useRouter}       from 'next/router';
import {useEffect}       from 'react';

export default function App({
	                            Component,
	                            pageProps,
                            }) {
	const router = useRouter();

	useEffect(() => {
		// Initialize Fathom when the app loads
		Fathom.load('YVEIRCMB', {
			includedDomains: [
				'atlas.dev',
			],
		});

		function onRouteChangeComplete() {
			Fathom.trackPageview();
		}

		// Record a pageview when route changes
		router.events.on('routeChangeComplete', onRouteChangeComplete);

		// Unassign event listener
		return () => {
			router.events.off('routeChangeComplete', onRouteChangeComplete);
		};
	}, [
		router.events,
	]);

	let layout = null;

	if (pageProps?.markdoc?.frontmatter?.layout) {
		layout = pageProps?.markdoc?.frontmatter?.layout;
	}

	if (router?.pathname?.startsWith('/blog/') && pageProps?.markdoc !== undefined) {
		layout = 'blog-article';
	}

	switch (layout) {
		case 'blog-article': {
			return (<BlogArticleLayout markdoc={pageProps?.markdoc}>
				<Component {...pageProps} />
			</BlogArticleLayout>);
		}

		case 'docs': {
			return (<DocsLayout markdoc={pageProps?.markdoc}>
				<Component {...pageProps} />
			</DocsLayout>);
		}

		default: {
			return (<Component {...pageProps} />);
		}
	}
}
