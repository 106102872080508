import clsx from 'clsx';
import Link from 'next/link';

const baseStyles = {
	solid:
		'group inline-flex items-center justify-center rounded-md py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 select-none',
	outline:
		'group inline-flex ring-1 items-center justify-center rounded-md py-2 px-4 text-sm focus:outline-none select-none',
	bare:
		'group inline-flex items-center justify-center rounded-md py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 select-none',
};

const variantStyles = {
	solid: {
		gray:
			  'bg-gray-900 text-white hover:bg-gray-700 hover:text-gray-100 active:bg-gray-800 active:text-gray-300 focus-visible:outline-gray-900',
		teal: 'bg-teal-600 text-white hover:text-teal-50 hover:bg-teal-500 active:bg-teal-800 active:text-teal-50 focus-visible:outline-teal-600',
		white:
			  'bg-white text-gray-900 hover:bg-gray-50 active:bg-gray-200 active:text-gray-800 focus-visible:outline-white',
	},
	outline: {
		gray:
			'ring-gray-200 text-gray-700 hover:text-gray-900 hover:ring-gray-300 active:bg-gray-100 active:text-gray-600 focus-visible:outline-teal-600 focus-visible:ring-gray-300',
		white:
			'ring-gray-700 text-white hover:ring-gray-500 active:ring-gray-700 active:text-gray-400 focus-visible:outline-white',
	},
	bare:    {
		white:
			'ring-gray-700 text-gray-900 hover:ring-gray-500 active:ring-gray-700 active:text-gray-400 focus-visible:outline-white',
	},
};

export function Button({
	                       variant = 'solid',
	                       color = 'gray',
	                       className,
	                       href,
	                       ...props
                       }) {
	className = clsx(
		baseStyles[variant],
		variantStyles[variant][color],
		className,
	);

	return href ? (
		<Link href={href}
		      className={className}
		      {...props}
		/>
	) : (
		<button className={className} {...props} />
	);
}
