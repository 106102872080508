import clsx from 'clsx';

export function Prose({
	                      as: Component = 'div',
	                      className,
	                      ...props
                      }) {
	return (<Component className={clsx([
		className,
		'prose prose-gray max-w-none',
		// headings
		'prose-headings:scroll-mt-8 prose-headings:font-display prose-headings:font-normal',
		// lead
		'prose-lead:text-gray-500',
		// links
		'prose-a:font-semibold',
		// link underline
		'prose-a:underline prose-a:underline-offset-2 prose-a:decoration-teal-500 prose-a:decoration-2',
		// pre
		'prose-pre:rounded-xl prose-pre:bg-gray-900 prose-pre:shadow-lg',
		// images
		'prose-img:border prose-img:border-teal-600 prose-img:rounded-lg prose-img:mx-auto prose-img:shadow-lg',
	])}
	                   {...props}
	/>);
}
